$(() => {
  const $globalWrapper = $('.js-global-wrapper');
  const $radialOverlay = $('.js-radial-overlay');

  $globalWrapper.on('mousemove', (e) => {
    $radialOverlay.css({
      top: e.clientY,
      left: e.clientX,
    });
  });

  // (() => {
  //   const delay = 3000;
  //   const $barrel = $('.js-barrel');
  //   const $items = $barrel.find('.barrel__item');
  //   const stages = {
  //     1: $items.filter('.is-stage-1'),
  //     2: $items.filter('.is-stage-2'),
  //     3: $items.filter('.is-stage-3'),
  //     4: $items.filter('.is-stage-4'),
  //     5: $items.filter('.is-stage-5'),
  //     6: $items.filter('.is-stage-6'),
  //   };

  //   const next = () => {
  //     stages[6].removeClass('is-stage-6');
  //     for (let i = 1; i < 6; i += 1) {
  //       stages[i].addClass(`is-stage-${i + 1}`).removeClass(`is-stage-${i}`);
  //     }

  //     let $nextItem = stages[1].next();
  //     if ($nextItem.length === 0) {
  //       $nextItem = $items.first();
  //     }
  //     $nextItem.addClass('is-stage-1');

  //     for (let i = 6; i > 1; i -= 1) {
  //       stages[i] = stages[i - 1];
  //     }
  //     stages[1] = $nextItem;

  //     setTimeout(next, delay);
  //   };

  //   setTimeout(next, delay);
  // })();
});
